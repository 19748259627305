import HttpService from "@/services/http-service";
import {OPSI_DUMMY_TOPS} from "@/plugins/constant";

const httpService = new HttpService();

export default ({
    async getList(filter)
    {
        const response = await httpService.get(`Spsh`, filter);
        const result = {
            pageNumber: 0,
            perPage: 0,
            totalPage: 0,
            totalRow: 0,
            data: Array()
        };

        if (!response.status)
        {
            response.data = result;
            return response;
        }

        result.pageNumber = response.data.pageNumber;
        result.perPage = response.data.perPage;
        result.totalPage = response.data.totalPage;
        result.totalRow = response.data.totalRow;
        for(let i = 0; i < response.data.data.length; i++)
        {
            result.data.push(this._toObject(response.data.data[i]));
        }

        response.data = result;
        return response;
    },

    async getObject(id) {
        const response = await httpService.get(`Spsh/${id}`);
        if (!response.status) {
            response.data = this.default();
            return response;
        }
        response.data = this._toObject(response.data);
        return response;
    },

    async create(model) {
        model = this._setDefaultValue(model);
        const response = await httpService.post('Spsh', model);
        if (!response.status) {
            response.data = this.default();
            return response;
        }
        response.data = this._toObject(response.data);
        return response;
    },

    async update(model, id) {
        model = this._setDefaultValue(model);
        const response = await httpService.update('Spsh', id, model);
        if (!response.status) {
            response.data = false;
        }
        return response;
    },

    async delete(id) {
        const response = await httpService.delete('Spsh', id);
        if (!response.status) {
            response.data = false;
        }
        return response;
    },


    default() {
        return {
            id :0,

            contractId :0,
            contractNumber :'',

            contractSp : '',
            contractSpCode:'',
            soldToPartyLabel:'',

            shipToId :0,
            shipToCode :'',
            shipToName : '',
            shipToPartyLabel:'',

            termsOfPaymentId :0,
            termsOfPaymentName : '',

            locationId :0,
            locationCode :'',

            diffrential :0,

            basePriceId :0,
            basePriceQuantity :0,
            basePriceProfileName :'',
            basePriceLabel :'',

            feeProfileId : 0,
            feeProfileName : '',

            unitId :0,
            unitCode :'-',

            currencyId : 0,
            currencyCode :''
        };
    },

    _setDefaultValue(jsonData) {
        if (jsonData === undefined || jsonData === null) {
            return this.default();
        }

        if (jsonData.contractId === null || jsonData.contractId === '') {
            jsonData.contractId = 0;
        }

        if (jsonData.shipToId === null || jsonData.shipToId === '') {
            jsonData.shipToId = 0;
        }

        if (jsonData.termsOfPaymentId === null || jsonData.termsOfPaymentId === '') {
            jsonData.termsOfPaymentId = 0;
        }

        if (jsonData.termsOfPaymentId === null || jsonData.termsOfPaymentId === '') {
            jsonData.termsOfPaymentId = 0;
        }

        if (jsonData.locationId === null || jsonData.locationId === '') {
            jsonData.locationId = 0;
        }

        if (jsonData.basePriceId === null || jsonData.basePriceId === '') {
            jsonData.basePriceId = 0;
        }

        if (jsonData.diffrential && typeof jsonData.diffrential === "string"){
            jsonData.diffrential = parseFloat(jsonData.diffrential);
        }

        if (jsonData.feeProfileId === null || jsonData.feeProfileId === '') {
            jsonData.feeProfileId = 0;
        }

        if (jsonData.unitId === null || jsonData.unitId === '') {
            jsonData.unitId = 0;
        }

        if (jsonData.currencyId === null || jsonData.currencyId === '') {
            jsonData.currencyId = 0;
        }

        return jsonData;
    },


    _toObject(jsonData) {
        const result = this.default();
        result.id = jsonData.id;

        result.contractId = jsonData.contractId;
        result.contractNumber = jsonData.contractNumber;

        result.contractSp = jsonData.contractSp;
        result.contractSpCode = jsonData.contractSpCode;
        result.soldToPartyLabel = `${jsonData.contractSpCode} - ${jsonData.contractSp}`;

        result.shipToId = jsonData.shipToId;
        result.shipToCode = jsonData.shipToCode;
        result.shipToName = jsonData.shipToName;
        result.shipToPartyLabel = `${jsonData.shipToCode} - ${jsonData.shipToName}`;

        result.termsOfPaymentId= jsonData.termsOfPaymentId;
        result.termsOfPaymentName= this.getNameTermsOfPayment(jsonData.termsOfPaymentId);

        result.locationId= jsonData.locationId;
        result.locationCode= jsonData.locationCode;

        result.diffrential= parseFloat(jsonData.diffrential);

        result.basePriceId= jsonData.basePriceId;
        result.basePriceQuantity= parseFloat(jsonData.basePriceQuantity);
        result.basePriceProfileName= jsonData.basePriceProfileName;

        result.feeProfileId= jsonData.feeProfileId;
        result.feeProfileName= jsonData.feeProfileName || jsonData.feeProfileId;

        result.unitId = jsonData.unitId;
        result.unitCode = jsonData.unitCode;

        result.currencyId = jsonData.currencyId;
        result.currencyCode = jsonData.currencyCode;

        return result;
    },

    getNameTermsOfPayment(value) {
        let result = '-';
        if (!(value === undefined || value === null || value === '' )) {
            result = OPSI_DUMMY_TOPS.filter((item) => item.id === value)[0].label
        }
        return result;
    }
});