<template>
  <router-view></router-view>
  <h1>Mapping SP - SH - Price</h1>
  <hr />
  <bs-error-message :errors="errorMessages" />

  <div class="row justify-content-between">
    <div class="col-md-6">
      <div class="d-grid gap-3 d-md-block">
        <router-link to="/spsh/create" class="btn btn-primary btn-sm me-md-1">
          <i class="bi bi-file-earmark"></i> Create
        </router-link>
      </div>
    </div>
    <div class="col-md-6">
      <div class="pagination-right">
        <bs-pagination :pageNumber="pageNumber" :perPage="perPage" :totalPage="totalPage" :totalRow="totalRow" @toPage="toPage" />
      </div>
    </div>
  </div>

  <div class="">
    <table class="table table-striped">
      <thead>
      <tr>
        <th rowspan="2">
          Action
        </th>
        <th>
          No. Contract
        </th>
        <th>
          Sold To
        </th>
        <th>
          Ship To
        </th>
        <th>
          Loc.
        </th>
        <th>
          Base Price
        </th>
        <th>
          Price
        </th>
        <th>
          <bs-ordering property="diffrential" @reorder="reorder">Diff.</bs-ordering>
        </th>
        <th>
          Fee
        </th>
        <th>
          Sales Currency
        </th>
        <th>
          Sales Unit
        </th>
        <th>
          ToP
        </th>
      </tr>
      <tr>
        <th>
          <div>
            <bs-textbox placeholder="No. Contract" v-model="filter.contractNumberContains" @keyup="search()" />
          </div>
        </th>
        <th>
          <div>
            <bs-textbox placeholder="Sold To" v-model="filter.soldToCodeOrNameContains" @keyup="search()" />
          </div>
        </th>
        <th>
          <div>
            <bs-textbox placeholder="Ship To" v-model="filter.shipToCodeOrNameContains" @keyup="search()" />
          </div>
        </th>
        <th>
          <div>
            <bs-textbox placeholder="Location" v-model="filter.locationCodeContains" @keyup="search()" />
          </div>
        </th>
        <th>
          <div>
            <bs-textbox placeholder="Base Price Profile" v-model="filter.profileNameContains" @keyup="search()" />
          </div>
        </th>
        <th>
          <div>
<!--            <bs-textbox placeholder="Base Price Profile" v-model="filter.profileNameContains" @keyup="search()" />-->
          </div>
        </th>
        <th>
          <div>
            <!--            <bs-textbox placeholder="Base Price Profile" v-model="filter.profileNameContains" @keyup="search()" />-->
          </div>
        </th>
        <th>
          <div>
            <!--            <bs-textbox placeholder="Base Price Profile" v-model="filter.profileNameContains" @keyup="search()" />-->
          </div>
        </th>
        <th>
          <div>
            <bs-textbox placeholder="Currency" v-model="filter.currencyCodeContains" @keyup="search()" />
          </div>
        </th>
        <th>
          <div>
            <bs-textbox placeholder="Unit" v-model="filter.unitCodeContains" @keyup="search()" />
          </div>
        </th>
        <th>
          <div>
<!--            <bs-textbox placeholder="Unit" v-model="filter.unitCodeContains" @keyup="search()" />-->
          </div>
        </th>
      </tr>
      </thead>
      <tbody>
      <template v-if="data != null && data.length > 0">
        <tr v-for="item in data" :key="item.id">
          <td>
            <div class="btn-group">
              <button class="btn btn-sm btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="bi bi-menu-down"></i> Action
              </button>
              <ul class="dropdown-menu">
                <li>
                  <button @click="detailItem(item)" class="dropdown-item"><i class="bi bi-eye"></i> View Details</button>
                </li>
                <li>
                  <button @click="editItem(item)" class="dropdown-item"><i class="bi bi-pencil-square"></i> Edit</button>
                </li>
<!--                <li>-->
<!--                    <button @click="deleteItem(item)" class="dropdown-item"><i class="bi bi-trash"></i> Delete</button>-->
<!--                </li>-->
              </ul>
            </div>
          </td>
          <td>
            {{item.contractNumber}}
          </td>

          <td>
            {{item.soldToPartyLabel}}
          </td>

          <td>
            {{item.shipToPartyLabel}}
          </td>

          <td>
            {{item.locationCode}}
          </td>

          <td>
            {{item.basePriceProfileName}}
          </td>

          <td>
            {{item.basePriceQuantity}}
          </td>

          <td>
            {{item.diffrential}}
          </td>

          <td>
            {{item.feeProfileName}}
          </td>

          <td>
            {{item.currencyCode}}
          </td>

          <td>
            {{item.unitCode}}
          </td>

          <td>
            {{item.termsOfPaymentName}}
          </td>
        </tr>
      </template>
      </tbody>
    </table>
  </div>

</template>

<script>
import SpshService from "@/services/spsh-service";

export default {
  components: {},
  data: function () {
    return {
      filter: {
        contractNumberContains: '',
        soldToCodeOrNameContains: '',
        shipToCodeOrNameContains: '',
        locationCodeContains: '',
        profileNameContains: '',
        currencyCodeContains: '',
        unitCodeContains: '',
        page: 1,
        perPage: 10,
        orderByProperty: 'id',
        orderType: 0
      },
      popupModel: {
        id: '',
        code: '',
        description: '',
        isDefault: '',
      },
      errorMessages: null,
      pageNumber: 1,
      perPage: 10,
      totalPage: 100,
      totalRow: 0,
      data: Array()
    }
  },
  watch: {
    $route: function() {
      if (this.$route.path == "/spsh") {
        this.loadData();
      }
    }
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async toPage(pageNumber) {
      this.filter.page = pageNumber;
      this.loadData();
    },
    async reorder(property, orderType) {
      this.filter.orderByProperty = property;
      this.filter.orderType = orderType;
      this.loadData();
    },
    async search() {
      this.filter.page = 1;
      this.loadData();
    },
    async loadData() {
      const result = await SpshService.getList(this.filter);
      if (result.status) {
        this.errorMessages = null;
      }
      else {
        this.errorMessages = result.errorMessages;
      }

      const data = result.data;
      this.pageNumber = data.pageNumber;
      this.perPage = data.perPage;
      this.totalPage = data.totalPage;
      this.totalRow = data.totalRow;
      this.data = data.data;
    },
    detailItem(item) {
      this.$router.push(`/spsh/detail/${item.id}`);
    },
    editItem(item) {
      this.$router.push(`/spsh/edit/${item.id}`);
    }
  }
}
</script>

<style>

</style>